
import NProgress from 'nprogress';
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: true })
export default (function () {
    return {
        $loading: null,
        Start() {
            NProgress.start()            
        },
        Stop() {
            NProgress.done()
        },

        Set(number) {
           let result =  (number/100)
            NProgress.set(result)            
        },

    };
})();



