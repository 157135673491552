<template>
<router-view />
</template>
<script>
    export default {
        name: 'App',
        components: {
        },
        data() {
            return {
                isAuthenticated: false,
                isActive: false,
            };
        },
        created() {},
        methods: {
            GetInfo(pageNo) {
            this.Info = [];
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.GetUsers(this.pageNo, this.pageSize, this.UserType)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch((error) => {
                    if (error.response.status==401) {
                        return  window.location = "/"
                 }
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        }
    }
</script>