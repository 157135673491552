import Add_Edit from "./Add_Edit/Add_Edit.vue";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Index",
  created() {
    this.GetInfo();
    this.GetAccounts();
  },

  components: {
    "Add-Edit": Add_Edit,
  },

  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },

  data() {
    return {
      Units: [],
      Accounts: [],
      dialogVisible: false,
      form: {
        Subject: "",
        Invoice: [],
        Account: "",
        subject: [],
        total: 0,
      },
      showModale: false,
      PhatOfSubjectImages: this.$http.envSubject(),
      PhatOfUnitsImages: this.$http.envUnit(),
      showImage: false,
      image: "",
      permissions: JSON.parse(localStorage.getItem("group")),
      Info: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,

      Category: false,
      YearOfStudy: 3,
      EditAddItem: [],
      Status: true,
      SubjectItem: "",
    };
  },
  watch: {
    "form.Subject"() {
      this.Info.forEach((el) => {
        if (el._id == this.form.Subject) {
          this.SubjectItem = el;
        }
      });
    },
    Category() {
      this.YearOfStudy = "";
    },
  },
  methods: {
    Save() {
      let SubjectIds = [];
      let UnitsIds = [];
      this.form.Invoice.forEach((el) => {
        UnitsIds.push(el._id);
      });
      this.form.subject.forEach((el) => {
        SubjectIds.push(el._id);
      });
      let schema = {
        Price: this.form.total,
        AccountId: this.form.Account,
        SubjectIds: SubjectIds,
        UnitsIds: UnitsIds,
      };

      Swal.fire({
        title: ` هـل انت متأكد عملية الشراء للطالب بإجمالي قيمة (${this.form.total} .دل) ?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .AddInvoice(schema, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.dialogVisible = false;
              this.form.Subject = "";
              this.form.Invoice = [];
              this.form.Account = "";
              this.form.subject = [];
              this.form.total = 0;
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },
    async GetAccounts() {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetAccounts(1, 10000, reCAPTCHA, false, "")
        .then((response) => {
          this.$blockUI.Stop();
          this.Accounts = response.data.info;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return  window.location = "/"
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },
    OpenDialogPurchase() {
      if (!this.form.Account) {
        return this.$helper.showWorning("الرجاء اختيار الحساب");
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    async GetUnits(pageNo) {
      this.Units = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetUnits(
          this.pageNo,
          this.pageSize,
          reCAPTCHA,
          this.YearOfStudy,
          false,
          this.form.Subject
        )
        .then((response) => {
          this.$blockUI.Stop();
          this.Units = response.data.info;
          this.pages = response.data.count;
          // this.Subjects = response.data.Subjects;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return  window.location = "/"
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },
    async GetInfo(pageNo) {
      this.Info = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetSubject(
          this.pageNo,
          this.pageSize,
          reCAPTCHA,
          this.YearOfStudy,
          false
        )
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.pages = response.data.count;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return  window.location = "/"
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },
    DActive(Id) {
      Swal.fire({
        title: "هـل انت متأكد من تعطيل النشر ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeSubjectStatus(Id, { status: true }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    OpenImageDialog(id) {
      this.showImage = true;
      this.image = this.$http.envSubject() + id;
    },
    Activate(Id) {
      Swal.fire({
        title: "هـل انت متأكد من  نشر ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeSubjectStatus(Id, { status: false }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.showModale = false;
              this.form.Subject = "";
              this.form.Invoice = [];
              this.form.Account = "";
              this.form.subject = [];
              this.total = 0;
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },
    Edit(item) {
      this.state = 2;
      this.EditAddItem = item;
    },
    Addpage() {
      this.state = 2;
      this.EditAddItem = null;
    },
    deleteUnit(item) {
      this.form.Invoice.forEach((el, index) => {
        if (el._id == item._id) {
          this.form.total -= item.Price;
          this.form.Invoice.splice(index, 1);
        }
      });
      this.form.Invoice = this.form.Invoice;
    },
    AddUnits(item) {
      let stat = false;
      this.form.subject.forEach((ele) => {
        if (item.Subject._id == ele._id) {
          stat = true;
        }
      });
      if (stat == true) {
        return this.$helper.showWorning(
          `لايمكن اضافة الوحدة نظراً لشراء المادة كاملة مع الوحدات`
        );
      }
      stat = false;
      this.form.Invoice.forEach((ele) => {
        if (item._id == ele._id) {
          stat = true;
        }
      });
      if (stat == false) {
        this.form.total += item.Price;
        return this.form.Invoice.push(item);
      }
      this.$helper.showWorning(`موجودة ${item.Name}   الوحدة`);
    },
    deleteSubject(item) {
      this.form.subject.forEach((el, index) => {
        if (el._id == item._id) {
          this.form.total -= item.PackageFullPrice;
          this.form.subject.splice(index, 1);
        }
      });
      this.form.subject = this.form.subject;
    },
    AddSubject() {
      let item = "";
      this.Info.forEach((ele) => {
        if (ele._id == this.form.Subject) {
          item = ele;
        }
      });
      let stat = false;
      this.form.subject.forEach((ele) => {
        if (item._id == ele._id) {
          stat = true;
        }
      });
      if (stat == false) {
        let array = [];
        this.form.Invoice.forEach((ele) => {
          if (ele.Subject._id != this.form.Subject) {
            array.push(ele);
          }
        });
        this.form.Invoice = array;
        this.form.subject.push(item);
        this.calculate();
        return;
      }
      this.$helper.showWorning(`موجودة ${item.Name}   المادة`);
    },
    calculate() {
      this.form.total = 0;
      this.form.subject.forEach((el) => {
        this.form.total += el.PackageFullPrice;
      });
      this.form.Invoice.forEach((e) => {
        this.form.total += e.Price;
      });
      return this.form.total;
    },
  },
};
