export default {
    name: 'AppFooter',    
    created() { 

        
    },
    data() {
        return {            
        };
    },
  
    methods: {
      
    }    
}
