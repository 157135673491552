import soundPath from "../../../assets/notifi.mp3";
export default {
  name: "Login",
  components: {},
  created() {
    this.logout();
    this.$blockUI.$loading = this.$loading;
    // if (localStorage.getItem("BSDB") == "true") {
    //   setTimeout(() => {
    //     window.location.href = "https://youtu.be/uRu26Zr7Jko?t=33";
    //   }, 5000);
    //   return this.$notify({
    //     title: "Sorry",
    //     dangerouslyUseHTMLString: true,
    //     message:
    //       "<strong>" +
    //       "Your device has been blocked contact the Admin Members. We are Legion. We do not forgive. We do not forget." +
    //       "</strong>",
    //     type: "error",
    //   });
    // }
  },
  data() {
    return {
      NewPassword: "",
      ConfirmPassword: "",
      otpState: false,
      state: 0,
      blokState: 0,
      isAuthenticated: false,
      isActive: false,
      ConfimPassword: "",
      forgetPasswordPhoneNumber: "",
      otp: "",
      form: {
        Password: null,
        Email: null,
      },
      signUp: {
        Name: "",
        PhoneNumber: "",
        Password: "",
        Email: "",
        Description: "",
      },
    };
  },
  methods: {
    async logout() {
      localStorage.clear();
      sessionStorage.clear();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .logout(reCAPTCHA)
        .then((e) => {})
        .catch((e) => {});
    },
    async PasswordReset() {
      if (!this.NewPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري ",
          type: "error",
        });
      }
      if (this.NewPassword.length <= 6) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري تحتوي علي سبعة ارقام ",
          type: "error",
        });
      }
      if (!this.ConfirmPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال تأكيد الرقم السري ",
          type: "error",
        });
      }
      if (this.NewPassword != this.ConfirmPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء التأكد من تطابق الرقم السري",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .AssignNewPassword(
          {
            NewPassword: this.NewPassword,
            forgetPasswordPhoneNumber: this.forgetPasswordPhoneNumber,
          },
          reCAPTCHA
        )
        .then(() => {
          this.$blockUI.Stop();
          this.state = 0;
          return this.$notify({
            type: "success",
            title: "لقد تم تعين كلمة المرور بنجاح يمكنك يسجيل دخول...",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },
    async ForgetPassowrd() {
      if (!this.forgetPasswordPhoneNumber) {
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء إدخال  رقم الهاتف" + "</strong>",
          type: "error",
        });
      } else if (!this.validPhone(this.forgetPasswordPhoneNumber)) {
        // this.increment();
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" + "الرجاء إدخال  رقم الهاتف بطريقة صحيحة" + "</strong>",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .ForgetPassword(
          { PhoneNumber: this.forgetPasswordPhoneNumber },
          reCAPTCHA
        )
        .then(() => {
          this.$blockUI.Stop();
          this.state = 4;
          return this.$notify({
            type: "success",
            title: "لقد تم إرسال رمز التحقق الي رقم الهاتف",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },
    async otpresend() {
      this.otpState = false;
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .otpresend({ PhoneNumber: this.signUp.PhoneNumber }, reCAPTCHA)
        .then(() => {
          setTimeout(() => {
            this.otpState = true;
          }, 20000);
          this.$blockUI.Stop();
          return this.$notify({
            type: "success",
            title: "لقد تم إرسال رمز التحقق من جديد",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },
    async checkOtpPassswordForget() {
      if (!this.otp) {
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message: "الرجاء إدخال  رمز التحقق",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .checkOtpForgetPassword(
          { PhoneNumber: this.forgetPasswordPhoneNumber, otp: this.otp },
          reCAPTCHA
        )
        .then(() => {
          this.state = 5;
          this.$blockUI.Stop();
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },

    async checkOtp() {
      if (!this.otp) {
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message: "الرجاء إدخال  رمز التحقق",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .checkOtp(
          { PhoneNumber: this.signUp.PhoneNumber, otp: this.otp },
          reCAPTCHA
        )
        .then(() => {
          this.state = 0;
          new Audio(soundPath).play();
          this.$blockUI.Stop();
          return this.$notify({
            type: "success",
            title: "Great",
            dangerouslyUseHTMLString: true,
            message:
              "<strong>" +
              "لقد تم تسجيل حساب سيتم اعلامك من قبل المنصة بتفعيل حسابك" +
              "</strong>",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },
    increment() {
      this.blokState += 1;
      if (this.blokState > 3) {
        localStorage.setItem("BSDB", true);
      }
    },
    SwitchMode(item) {
      this.state = item;
    },
    async Checks() {
      // if (localStorage.getItem("BSDB") == "true") {
      //   setTimeout(() => {
      //     window.location.href = "https://youtu.be/uRu26Zr7Jko?t=33";
      //   }, 7000);
      //   return this.$notify({
      //     title: "Sorry",
      //     dangerouslyUseHTMLString: true,
      //     message:
      //       "<strong>" +
      //       "Your device has been blocked contact the Admin.  We are Legion. We do not forgive. We do not forget." +
      //       "</strong>",
      //     type: "error",
      //   });
      // }

      if (!this.signUp.PhoneNumber) {
        // this.increment();
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء إدخال  رقم الهاتف" + "</strong>",
          type: "error",
        });
      } else if (!this.validPhone(this.signUp.PhoneNumber)) {
        // this.increment();
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" + "الرجاء إدخال  رقم الهاتف بطريقة صحيحة" + "</strong>",
          type: "error",
        });
      }

      if (!this.signUp.Password) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري ",
          type: "error",
        });
      }
      if (this.signUp.Password.length <= 6) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري تحتوي علي سبعة ارقام ",
          type: "error",
        });
      }

      if (!this.ConfimPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال تأكيد الرقم السري ",
          type: "error",
        });
      }

      if (this.signUp.Password != this.ConfimPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء التأكد من تطابق الرقم السري",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .AddTeacher(this.signUp, reCAPTCHA)
        .then(() => {
          this.$blockUI.Stop();
          this.signUp.Name = "";
          // this.signUpPhoneNumber = "";
          this.signUpPassword = "";
          this.signUpEmail = "";
          this.signUpDescription = "";
          this.state = 2;
          setTimeout(() => {
            this.otpState = true;
          }, 20000);
          return this.$notify({
            type: "success",
            title: "Great",
            dangerouslyUseHTMLString: true,
            message:
              "<strong>" +
              "لقد تم تسجيل حساب وإرسال رمز التحقق على رقم الهاتف" +
              "</strong>",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          this.$notify({
            title: "خطأ",
            dangerouslyUseHTMLString: true,
            message: "<strong>" + error.response.data.Message + "</strong>",
            type: "error",
          });
        });
    },
    signUp2() {
      this.increment();
      // if (localStorage.getItem("BSDB") == "true") {
      //   setTimeout(() => {
      //     window.location.href = "https://youtu.be/uRu26Zr7Jko?t=33";
      //   }, 7000);

      //   return this.$notify({
      //     title: "Sorry",
      //     dangerouslyUseHTMLString: true,
      //     message:
      //       "<strong>" +
      //       "Your device has been blocked contact the Admin  We are Legion. We do not forgive. We do not forget." +
      //       "</strong>",
      //     type: "error",
      //   });
      // }
      // this.$notify({
      //   title: "Sorry",
      //   dangerouslyUseHTMLString: true,
      //   message: "<strong>" + "Do you think it's that easy?" + "</strong>",
      //   type: "error",
      // });
    },
    validPhone: function (Phone) {
      var mobileRegex = /^09[1|2|3|4|5][0-9]{7}$/i;
      return mobileRegex.test(Phone);
    },
    async login() {
      // if (localStorage.getItem("BSDB") == "true") {
      //   setTimeout(() => {
      //     window.location.href = "https://youtu.be/uRu26Zr7Jko?t=33";
      //   }, 7000);
      //   return this.$notify({
      //     title: "Sorry",
      //     dangerouslyUseHTMLString: true,
      //     message:
      //       "<strong>" +
      //       "Your device has been blocked contact the Admin.  We are Legion. We do not forgive. We do not forget." +
      //       "</strong>",
      //     type: "error",
      //   });
      // }

      if (!this.form.Phone) {
        this.increment();
        return this.$notify({
          title: "خطأ",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء إدخال  رقم الهاتف" + "</strong>",
          type: "error",
        });
      } else if (!this.validPhone(this.form.Phone)) {
        this.increment();
        return this.$notify({
          title: "خطأ",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" + "الرجاء إدخال  رقم الهاتف بطريقة صحيحة" + "</strong>",
          type: "error",
        });
      }

      if (!this.form.Password) {
        this.$notify({
          title: "خطأ",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء إدخال الرقم السري" + "</strong>",
          type: "error",
        });
        this.increment();
        return;
      }

      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .login(this.form, reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          localStorage.setItem("Token", response.data.token);
          localStorage.setItem("role", response.data.role);
          localStorage.setItem("transmission", response.data.type);
          localStorage.setItem("name", response.data.name);
          if (response.data.image) {
            localStorage.setItem("image", response.data.image);
          }
          window.location.href = "#/Home";
        })
        .catch((error) => {
          this.$blockUI.Stop();
          this.increment();
          this.$notify({
            title: "خطأ",
            dangerouslyUseHTMLString: true,
            message: "<strong>" + error.response.data.Message + "</strong>",
            type: "error",
          });
        });
    },
  },
};
