export default {
  name: "Actions",
  created() {
    this.form.Name = this.$parent.EditObj?.Name;
    this.form.Id = this.$parent.EditObj?._id;
    this.form.Home = this.$parent.EditObj?.Home;
    this.form.Reports = this.$parent.EditObj?.Reports;
    this.form.Student = this.$parent.EditObj?.Student;
    this.form.Teachers = this.$parent.EditObj?.Teachers;
    this.form.Users = this.$parent.EditObj?.Users;
    this.form.Subjects = this.$parent.EditObj?.Subjects;
    this.form.Categories = this.$parent.EditObj?.Categories;
    this.form.Groups = this.$parent.EditObj?.Groups;
  },
  data() {
    return {
      Municipalities: [],
      MunicipalitId: "",
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      UserType: [],
      Hospitals: [],
      form: {
        Name: "",
        Home:false,
        Reports:false,
        Student:false,
        Teachers:false,
        Users:false,
        Subjects:false,
        Categories:false,
        Groups:false,
      },
    };
  },
  methods: {
    



    Back() {
      this.$parent.state = 0;
    },
    resetform() {
      this.form.Name = null;
    },
  async  Save() {
      this.$blockUI.Start();
      if (!this.form.Name) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الاسم ",
        });
        return;
      }
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha('login');
      if (this.$parent.EditObj) {
        return this.$http
          .UpdateGroups(this.$parent.EditObj._id, this.form,reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
             
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }

      this.$http
        .AddGroup(this.form,reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;
           
          this.$blockUI.Stop();
        })
        .catch((err) => {
          //
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
  },
};
