import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Index",
  created() {
    this.GetInfo();
    // this.DistributorActive();
  },

  components: {},

  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },

  data() {
    return {
      activeName:"third",
      PAPIkey: "",
      PAppId: "",
      DAppId: "",
      DAPIkey: "",
      SearchDistributor: "",
      Id: "",
      Statement: "",
      Distributor: "",
      percentage: "",
      openDialog: false,
      phoneNumber: "",
      showImage: false,
      image: "",
      SearchOrder: "",
      permissions: JSON.parse(localStorage.getItem("group")),
      Info: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Status: false,
      EditAddItem: [],
      Distributors: [],
    };
  },
  methods: {
    copy(number) {
      if (number == 1) {
        this.$refs.APIkey.focus();
        document.execCommand("copy");
      }
      if (number == 2) {
        this.$refs.AppId.focus();
        document.execCommand("copy");
      }
      if (number == 3) {
        this.$refs.DAPIkey.focus();
        document.execCommand("copy");
      }
      if (number == 4) {
        this.$refs.DAppId.focus();
        document.execCommand("copy");
      }
    },
    async GetInfo() {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetAPIkeys(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.PAPIkey = response.data.info.key_id;
          this.PAppId = response.data.info.app_id;
          this.DAppId = response.data.info.dev_app_id;
          this.DAPIkey = response.data.info.dev_key_id;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },

    GenerateDev() {
      Swal.fire({
        title: "هل انت متأكد؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .GenerateDev(reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              });
              return this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    GenerateProduction() {
      Swal.fire({
        title: "هل انت متأكد؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .GenerateProduction(reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              });
              return this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    OpenImageDialog(id) {
      this.showImage = true;
      this.image = this.$http.envTeachers() + id;
    },
    Activate(Id) {
      Swal.fire({
        title: "هـل انت متأكد من  تفعيل ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .CategoryChangeStatus(Id, { status: false }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },

    Edit(item) {
      this.Id = item._id;
      this.Statement = item.Statement;
      this.Distributor = item.Distributor?._id;
      this.percentage = item.percentage;
      this.openDialog = true;
    },

    Addpage() {
      this.Id = "";
      this.Statement = "";
      this.Distributor = "";
      this.percentage = "";
      this.openDialog = true;
    },
  },
};
