import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Index",
  created() {
    this.GetInfo();
  },

  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },

  data() {
    return {
      phoneNumber:"",
      Phone: "",
      Password: "",
      Id: "",
      Name: "",
      openDialog: false,
      phoneNumber: "",
      showImage: false,
      image: "",
      permissions: JSON.parse(localStorage.getItem("group")),
      Info: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Status: false,
      EditAddItem: [],
    };
  },
  methods: {
    Clear(){
      this.phoneNumber = '';
      this.GetInfo(1);
    },
    async Save() {
      this.$blockUI.Start();
      if (!this.Name || !this.Password || !this.Phone) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال اسم الموزع ",
        });
        return;
      }
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      if (this.Id) {
        return this.$http
          .UpdateDistributor(
            this.Id,
            { Name: this.Name, Password: this.Password, Phone: this.Phone },
            reCAPTCHA
          )
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.GetInfo(1);
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }
      this.$http
        .CreateDistributor(
          { Name: this.Name, Password: this.Password, Phone: this.Phone },
          reCAPTCHA
        )
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.GetInfo(1);
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          console.log(err);
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
    async GetInfo(pageNo) {
      this.Info = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetCustomer(this.pageNo, this.pageSize,this.phoneNumber,reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.result;
          this.pages = response.data.count;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return  window.location = "/"
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },

    DActive(Id) {
      Swal.fire({
        title: "هـل انت متأكد من ايقاف تفعيل ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeDistributorState(Id, { status: true }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    OpenImageDialog(id) {
      this.showImage = true;
      this.image = this.$http.envTeachers() + id;
    },
    Activate(Id) {
      Swal.fire({
        title: "هـل انت متأكد من  تفعيل ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeDistributorState(Id, { status: false }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },
    Edit(item) {
      this.Id = item._id;
      this.Name = item.Name;
      this.Phone = item.Phone;
      this.Password = '';
      this.openDialog = true;
    },
    Addpage() {
      this.openDialog = true;
      this.Id = "";
      this.Phone = '';
      this.Password = '';
      this.Name = "";
    },
  },
};
