export default {
    name: "EditAdd",
    created() {
      this.form.Id = this.$parent.EditAddItem?._id;
      this.form.Name = this.$parent.EditAddItem?.Name;
      this.form.Email = this.$parent.EditAddItem?.Email;
      this.form.PhoneNumber = this.$parent.EditAddItem?.PhoneNumber;
      this.form.Description = this.$parent.EditAddItem?.Description;
      this.form.PhotoFullPath = this.$parent.EditAddItem?.PhotoFullPath;
    },
    data() {
      return {
        form: {
            Name:"",
            Email:"",
            Id:"",
            PhoneNumber:"",
            Description:"",
            PhotoFullPath:"",
        },
      };
    },
    methods: {
      Back() {
        this.$parent.state = 0;
      },
      validEmail: function(email) {
        var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      validLoginName: function(LoginName) {
        var login = /^[a-zA-Z]{0,40}$/;
        return login.test(LoginName);
      },
      validFullName: function(Name) {
        var loginArabic = /^[\u0621-\u064A\u0660-\u0669 ]+$/;
        return loginArabic.test(Name);
      },
  
      validPassword: function(Password) {
        var PasswordT = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}.*$/;
  
        return PasswordT.test(Password);
      },
      validPhone: function(Phone) {
        var mobileRegex = /^09[1|2|3|4|5][0-9]{7}$/i;
  
        return mobileRegex.test(Phone);
      },
      FileChanged(e) {
        this.form.PhotoFullPath = e.raw
        // this.ruleForm.fileList.push(e.raw);
      },
      async Save() {
        this.$blockUI.Start();
        if (!this.form.Name) {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: "الرجاء إدخال الاسم ",
          });
          return;
        }
  
        if (!this.form.Email) {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: "الرجاء إدخال البريد الإلكتروني ",
          });
          return;
        } else if (!this.validEmail(this.form.Email)) {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: "الرجاء إدخال البريد الإلكتروني بطريقه صحيحه ",
          });
          return;
        }
        if (!this.form.PhoneNumber) {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: "الرجاء رقم الهاتف ",
          });
          return;
        } else if (!this.validPhone(this.form.PhoneNumber)) {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
          });
          return;
        }
        //this.form.UserType = this.$parent.UserType;
        //this.form.OfficeId = (this.$parent.OfficeIdValue == "" ? 0 : this.$parent.OfficeIdValue);

        await this.$recaptchaLoaded();
        let reCAPTCHA = await this.$recaptcha('login');
        if (this.$parent.EditAddItem) {
          let form = new FormData()
          form.append('Name',this.form.Name)
          form.append('Email',this.form.Email)
          form.append('PhoneNumber',this.form.PhoneNumber)
          form.append('Description',this.form.Description)
          form.append('PhotoFullPath',this.form.PhotoFullPath)
          return this.$http
            .updateTeacher(this.$parent.EditAddItem._id, form, reCAPTCHA)
            .then((response) => {
              this.$message({
                type: "info",
                message: response.data.Message,
              });
              this.$parent.GetInfo(this.$parent.pageNo);
              this.$parent.state = 0;
               
              this.$blockUI.Stop();
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$message({
                type: "error",
                message: err.response.data.Message,
              });
            });
        }
        let form = new FormData();
        form.append('Name',this.form.Name)
        form.append('Email',this.form.Email)
        form.append('PhoneNumber',this.form.PhoneNumber)
        form.append('Description',this.form.Description)
        form.append('PhotoFullPath',this.form.PhotoFullPath)
        this.$http
          .AddTeacher(form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
             
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      },
    },
  };
  