// src/store/progress.js
import { defineStore, createPinia } from "pinia";
import Vue from "vue";

const pinia = createPinia();
Vue.use(pinia);
export const useProgressStore = defineStore("progress", {
  state: () => ({
    uploadProgress: [],
  }),
  actions: {
    setUploadProgress(progress) {
      this.uploadProgress.unshift(progress);
    },
    UpdateUploadProgress(progress) {
      this.uploadProgress.forEach((el) => {
        if (el.id == progress.id) {
          el.progress = progress.progress;
        }
      });
    },
  },
});
