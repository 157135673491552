export default {
    name: "EditAdd",
    created() {
      this.form.Id = this.$parent.EditAddItem?._id;
      this.form.Name = this.$parent.EditAddItem?.Name;
      this.form.id = this.$parent.EditAddItem?.id;

    },
    data() {
      return {
        form: {
            Name:"",
            id:"",
            Id:"",
            
        },
      };
    },
    methods: {
      Back() {
        this.$parent.state = 0;
      },
      async Save() {
        this.$blockUI.Start();
        if (!this.form.Name || !this.form.id) {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: "الرجاء إدخال الاسم ",
          });
          return;
        }
        await this.$recaptchaLoaded();
        let reCAPTCHA = await this.$recaptcha('login');
        if (this.$parent.EditAddItem) {
          return this.$http
            .updateCategory(this.$parent.EditAddItem._id, this.form, reCAPTCHA)
            .then((response) => {
              this.$message({
                type: "info",
                message: response.data.Message,
              });
              this.$parent.GetInfo(this.$parent.pageNo);
              this.$parent.state = 0;
              this.$blockUI.Stop();
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$message({
                type: "error",
                message: err.response.data.Message,
              });
            });
        }
        this.$http
          .createCategory(this.form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            console.log(err);
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      },
    },
  };
  