import axios from "axios";
//const baseUrl = 'http://localhost:4810/Api';
let Url = process.env.VUE_APP_URL;
let imagePathTeachers = process.env.VUE_APP_URL + "images/Teachers/";
let imagePathSubject = process.env.VUE_APP_URL + "images/Subjects/";
let imagePathUnit = process.env.VUE_APP_URL + "images/Units/";

export default {
  envTeachers() {
    return imagePathTeachers;
  },
  envSubject() {
    return imagePathSubject;
  },
  envUnit() {
    return imagePathUnit;
  },

  CreateDistributor(form, reCAPTCHA) {
    return axios.post(Url + `Distributor/`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  UpdateDistributor(id, form, reCAPTCHA) {
    return axios.patch(Url + `Distributor/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  UpDateVR(id, form, reCAPTCHA) {
    return axios.patch(Url + `UpDateVR/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  CreateBulkVouchers(form, reCAPTCHA) {
    return axios.post(Url + `CreateBulkVouchers`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  GetVouchersHomeStatics(reCAPTCHA) {
    return axios.get(Url + `GetVouchersHomeStatics`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GetVouchersHomeStaticsSearch(clientId, reCAPTCHA) {
    return axios.get(
      Url + `GetVouchersHomeStaticsSearch?clientId=${clientId}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },
  CheckCard(pageno, pagesize, value, sn, isPurchased, isTest, reCAPTCHA) {
    return axios.get(
      Url +
        `CheckCard?pageno=${pageno}&pagesize=${pagesize}&value=${value}&sn=${sn}&isPurchased=${isPurchased}&isTest=${isTest}
        `,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },
  GetAllForSearch(reCAPTCHA) {
    return axios.get(Url + `GetAllForSearch`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GetVouchers(
    pageno,
    pagesize,
    RVId,
    value,
    sn,
    isPurchased,
    isTest,
    reCAPTCHA
  ) {
    return axios.get(
      Url +
        `GetVouchers?pageno=${pageno}&pagesize=${pagesize}&RVId=${RVId}&value=${value}&sn=${sn}&isPurchased=${isPurchased}&isTest=${isTest}
        `,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  GetCardsForExl(RVId, value, isTest, reCAPTCHA) {
    return axios.get(
      Url + `GetCardsForExl?RVId=${RVId}&value=${value}&isTest=${isTest}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  GetVouchersStatice(RVId, value, isPurchased, isTest, reCAPTCHA) {
    return axios.get(
      Url +
        `GetVouchersStatice?RVId=${RVId}&value=${value}&isPurchased=${isPurchased}&isTest=${isTest}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  GenerateDev(reCAPTCHA) {
    return axios.post(
      Url + `GenerateDev`,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  GetAPIkeys(reCAPTCHA) {
    return axios.get(Url + `GetAPIkeys`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GenerateProduction(reCAPTCHA) {
    return axios.post(
      Url + `GenerateProduction`,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  GetVR(pageno, pagesize, Distributor, order, reCAPTCHA) {
    return axios.get(
      Url +
        `GetVRs?pageno=${pageno}&pagesize=${pagesize}&Distributor=${Distributor}&order=${order}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  GetVRClient(pageno, pagesize, clientId, reCAPTCHA) {
    return axios.get(
      Url + `GetVRs?pageno=${pageno}&pagesize=${pagesize}&clientId=${clientId}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  RedeemCardsVouchersDashboard(id, reCAPTCHA) {
    return axios.post(
      Url + `RedeemCardsVouchersDashboard?id=${id}`,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  GetCustomer(pageNo, pageSize, phoneNumber, reCAPTCHA) {
    return axios.get(
      Url +
        `GetCustomer?pageno=${pageNo}&pagesize=${pageSize}&phoneNumber=${phoneNumber}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  redeemOutSide(form, reCAPTCHA) {
    return axios.post(Url + `redeemOutSide`, form, {
      headers: {
        recaptcha: reCAPTCHA,
      },
    });
  },

  redeemAPIGAZA(form, reCAPTCHA) {
    return axios.post(
      Url + `card/redeem/musrata`,
      {
        voucher: form,
      },
      {
        headers: {
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  ShowCard(id, reCAPTCHA) {
    return axios.get(Url + `ShowCard?id=${id}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  GetActiveCategories(reCAPTCHA) {
    return axios.get(Url + `GetActiveCategories`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  GetUrlGenerated(reCAPTCHA) {
    return axios.get(Url + `GetUrlGenerated`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  generateUrl(reCAPTCHA) {
    return axios.put(
      Url + `generateUrl`,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },
  getCategories(pageNo, pageSize, isDeleted, reCAPTCHA) {
    return axios.get(
      Url +
        `categories?pageno=${pageNo}&pagesize=${pageSize}&isDeleted=${isDeleted}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  createCategory(form, reCAPTCHA) {
    return axios.post(Url + `categories`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  updateCategory(id, form, reCAPTCHA) {
    return axios.patch(Url + `categories/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  changeStateOfCategory(id, status, reCAPTCHA) {
    return axios.put(
      Url + `categories/${id}`,
      { status: status },
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  CreateVR(form, reCAPTCHA) {
    return axios.post(Url + `CreateVR/`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  DistributorActive(reCAPTCHA) {
    return axios.get(Url + `DistributorActive/`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  ChangeDistributorState(id, status, reCAPTCHA) {
    return axios.put(Url + `Distributor/${id}`, status, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GetDistributor(pageNo, pageSize, reCAPTCHA) {
    return axios.get(
      Url + `Distributor?pageNo=${pageNo}&pagesize=${pageSize}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  // ********************************************************************| Authintecations |*********************************************************** \\
  login(bodyObjeect, reCAPTCHA) {
    return axios.post(Url + `Security/loginAdmin`, bodyObjeect, {
      headers: { recaptcha: reCAPTCHA },
      withCredentials: true,
    });
  },

  // ********************************************************************| Authintecations |*********************************************************** \\

  ////////////////////////////////////////////////////////////////  Group /////////////////////////////////////////////////////
  GetGroups(pageNo, pageSize, reCAPTCHA) {
    return axios.get(Url + `/GetGroups?pageNo=${pageNo}&pagesize=${pageSize}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  AddGroup(bodyObject, reCAPTCHA) {
    return axios.post(Url + `CreateGroups/`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  DeleteVideo(bodyObject, reCAPTCHA) {
    return axios.post(Url + `VideoDespatch/`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  VideoUpdateWithOut(id, bodyObject, reCAPTCHA) {
    return axios.put(Url + `VideoUpdateWithOut/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  TeacherUpdateProfile(form, reCAPTCHA) {
    return axios.put(Url + `TeacherUpdateProfile`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  GetTesterProfile(reCAPTCHA) {
    return axios.get(Url + `TeacherProfile`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  videoFilesWithFile(id, bodyObject, reCAPTCHA) {
    return axios.put(Url + `videoFilesWithFile/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  videoFilesWithOutFile(id, bodyObject, reCAPTCHA) {
    return axios.put(Url + `videoFilesWithOutFile/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  videoFilesPublish(id, bodyObject, reCAPTCHA) {
    return axios.put(Url + `videoFilesPublish/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  QuestionPublish(id, bodyObject, reCAPTCHA) {
    return axios.put(Url + `QuestionPublish/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  DeleteQuestionsFIle(id, reCAPTCHA) {
    return axios.delete(Url + `DeleteQuestionsFIle/${id}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  CreateExam(id, bodyObject, reCAPTCHA) {
    return axios.post(Url + `CreateExam/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  videoFilesDelete(id, reCAPTCHA) {
    return axios.delete(Url + `videoFilesDelete/${id}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  getFilesOfVideo(id, reCAPTCHA, pageNo = 1, pageSize = 10) {
    return axios.get(
      Url + `videoFiles/${id}?pageno=${pageNo}&pagesize=${pageSize}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  QuestionWithFile(id, body, reCAPTCHA) {
    return axios.post(Url + `QuestionWithFile/${id}`, body, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  DeleteQuestions(id, reCAPTCHA) {
    return axios.delete(Url + `QuestionDelete/${id}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  QuestionWithOutFile(id, body, reCAPTCHA) {
    return axios.post(Url + `QuestionWithOutFile/${id}`, body, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  Questions(id, reCAPTCHA, pageNo = 1, pageSize = 10) {
    return axios.get(
      Url + `QuestionGetAll/${id}?pageno=${pageNo}&pagesize=${pageSize}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  AddVideoFile(bodyObject, reCAPTCHA) {
    return axios.post(Url + `videoFiles`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  VideoUpdateWithFile(id, bodyObject, reCAPTCHA) {
    return axios.put(Url + `VideoUpdateWithFile/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  QuestionUpdateWithOutFile(id, bodyObject, reCAPTCHA) {
    return axios.put(Url + `QuestionUpdateWithOutFile/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  QuestionUpdateWithFile(id, bodyObject, reCAPTCHA) {
    return axios.put(Url + `QuestionUpdateWithFile/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  UpdateGroups(id, bodyObject, reCAPTCHA) {
    return axios.patch(Url + `EditGroup/${id}`, bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  DeleteGroup(id, reCAPTCHA) {
    return axios.delete(Url + `DeleteGroup/${id}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  ////////////////////////////////////////////////////////////////  Group /////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////  Users /////////////////////////////////////////////////////
  GetUsers(pageNo, pageSize, UserType, reCAPTCHA) {
    return axios.get(
      Url +
        `/Security/GetAllUsers?pageNo=${pageNo}&pagesize=${pageSize}&userType=${UserType}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  updateUser(Id, object, reCAPTCHA) {
    return axios.patch(Url + `Security/UpdateUser/${Id}`, object, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  AddUser(bodyObject, reCAPTCHA) {
    return axios.post(Url + "Security/SignUpUser", bodyObject, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  ChangeStatusUser(id, reCAPTCHA) {
    return axios.post(
      Url + "Security/ChangeStatus/" + id,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },
  ////////////////////////////////////////////////////////////////  Users /////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////  Teachers ////////////////////////////////////////////////////

  GetTeachers(pageNo, pageSize, reCAPTCHA, Status, phoneNumber) {
    return axios.get(
      Url +
        `Teachers?pageno=${pageNo}&pagesize=${pageSize}&Status=${Status}&phoneNumber=${phoneNumber}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  updateTeacher(id, form, reCAPTCHA) {
    return axios.put(Url + `Teacher/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  AddTeacher(form, reCAPTCHA) {
    return axios.post(Url + `Teacher`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  ChangeStatus(id, stauts, reCAPTCHA) {
    return axios.put(Url + `TeacherChangeStatus/${id}`, stauts, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  GetCategory(pageNo, pageSize, reCAPTCHA) {
    return axios.get(Url + `Category?pageno=${pageNo}&pagesize=${pageSize}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  // updateCategory(id, form, reCAPTCHA) {
  //   return axios.put(Url + `Category/${id}`, form, {
  //     headers: {
  //       Authorization: localStorage.getItem("Token"),
  //       recaptcha: reCAPTCHA,
  //     },
  //   });
  // },

  AddCategory(form, reCAPTCHA) {
    return axios.post(Url + `Category`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  CategoryChangeStatus(id, stauts, reCAPTCHA) {
    return axios.put(Url + `CategoryChangeStatus/${id}`, stauts, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  ////////////////////////////////////////////////////////////////  Teachers ///////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  GetSubCategory(pageNo, pageSize, reCAPTCHA) {
    return axios.get(
      Url + `SubCategory?pageno=${pageNo}&pagesize=${pageSize}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  updateSubCategory(id, form, reCAPTCHA) {
    return axios.put(Url + `SubCategory/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  AddSubCategory(form, reCAPTCHA) {
    return axios.post(Url + `SubCategory`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  SubCategoryChangeStatus(id, stauts, reCAPTCHA) {
    return axios.put(Url + `SubCategoryChangeStatus/${id}`, stauts, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GeActiveCategory(reCAPTCHA) {
    return axios.get(Url + `GeActiveCategory/`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GeActiveSubCategory(id, reCAPTCHA) {
    return axios.get(Url + `GeActiveSubCategory/${id}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  ////////////////////////////////////////////////////////////////  Teachers ///////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////  Subject ///////////////////////////////////////////////////
  GetSubject(pageNo, pageSize, reCAPTCHA, SubCategory, Status) {
    return axios.get(
      Url +
        `Subjects?pageno=${pageNo}&pagesize=${pageSize}&subCategory=${SubCategory}&Status=${Status}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  logout(reCAPTCHA) {
    return axios.post(
      Url + `logout`,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  AddSubject(form, reCAPTCHA) {
    return axios.post(Url + `Subject`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  updateSubject(id, form, reCAPTCHA) {
    return axios.put(Url + `Subject/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  AllSubjects(subCategory, reCAPTCHA) {
    return axios.get(Url + `AllSubjects?SubCategory=${subCategory}`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  ChangeSubjectStatus(id, stauts, reCAPTCHA) {
    return axios.put(Url + `ChangeSubjectStatus/${id}`, stauts, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  ////////////////////////////////////////////////////////////////  Subject ///////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////  Units ///////////////////////////////////////////////////

  AddUnit(form, reCAPTCHA) {
    return axios.post(Url + `Unit`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  updateUnits(id, form, reCAPTCHA) {
    return axios.put(Url + `Unit/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  ChangeUnitsStatus(id, stauts, reCAPTCHA) {
    return axios.put(Url + `ChangeUnitStatus/${id}`, stauts, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GetUnits(
    pageNo,
    pageSize,
    reCAPTCHA,
    // SubCategory,
    Status,
    Subject
  ) {
    return axios.get(
      Url +
        `Units?pageNo=${pageNo}&pagesize=${pageSize}&Status=${Status}&Subject=${Subject}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },
  ////////////////////////////////////////////////////////////////  Units ///////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////  Accounts ///////////////////////////////////////////////////

  updateParents(id, form, reCAPTCHA) {
    return axios.patch(Url + `Parents/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  AddParents(form, reCAPTCHA) {
    return axios.post(Url + `Parents`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GetParents(pageNo, pageSize, reCAPTCHA, Status, phoneNumber) {
    return axios.get(
      Url +
        `Parents?pageNo=${pageNo}&pagesize=${pageSize}&Status=${Status}&phoneNumber=${phoneNumber}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  GetParentsAll(reCAPTCHA) {
    return axios.get(Url + `ParentsAll`, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  ParentsChangeStatus(id, stauts, reCAPTCHA) {
    return axios.put(Url + `ParentsChangeStatus/${id}`, stauts, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  ////////////////////////////////////////////////////////////////  Accounts ///////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////  Accounts ///////////////////////////////////////////////////

  updateAccount(id, form, reCAPTCHA) {
    return axios.patch(Url + `Accounts/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  AddAccount(form, reCAPTCHA) {
    return axios.post(Url + `Accounts`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  GetAccounts(pageNo, pageSize, reCAPTCHA, Status, phoneNumber) {
    return axios.get(
      Url +
        `Accounts?pageNo=${pageNo}&pagesize=${pageSize}&Status=${Status}&phoneNumber=${phoneNumber}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  AccountsChangeStatus(id, stauts, reCAPTCHA) {
    return axios.put(Url + `AccountsChangeStatus/${id}`, stauts, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  uploadFile(form) {
    return axios.put(Url + `uploadFile/`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
      },
    });
  },

  AddVideo(id, form, reCAPTCHA) {
    return axios.post(Url + `videos/${id}`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  GetVideos(id, pageNo, pageSize, reCAPTCHA) {
    return axios.get(
      Url + `videos/${id}?pageno=${pageNo}&pagesize=${pageSize}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },

  AddInvoice(form, reCAPTCHA) {
    return axios.post(Url + `Invoice`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  CreateEndSubscription(form, reCAPTCHA) {
    return axios.post(Url + `EndSubscription`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  checkOtp(form, reCAPTCHA) {
    return axios.post(Url + `/check/otp`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  checkOtpForgetPassword(form, reCAPTCHA) {
    return axios.post(Url + `/check/otpFogetPassword`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
  otpresend(form, reCAPTCHA) {
    return axios.post(Url + `/otp/resend`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  ForgetPassword(form, reCAPTCHA) {
    return axios.post(Url + `/ForgetPassword/`, form, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },

  AssignNewPassword(form, reCAPTCHA, token) {
    return axios.post(Url + `/AssignNewPassword/`, form, {
      headers: {
        Authorization: token,
        recaptcha: reCAPTCHA,
      },
    });
  },

  //
  GetEndSubscription(pageno, pagesize, Status, reCAPTCHA) {
    return axios.get(
      Url +
        `EndSubscription?pageno${pageno}&pagesize=${pagesize}&Status=${Status}`,
      {
        headers: {
          Authorization: localStorage.getItem("Token"),
          recaptcha: reCAPTCHA,
        },
      }
    );
  },
  ChangeEndSubscription(id, body, reCAPTCHA) {
    return axios.put(Url + `EndSubscription/${id}`, body, {
      headers: {
        Authorization: localStorage.getItem("Token"),
        recaptcha: reCAPTCHA,
      },
    });
  },
};
