export default {
  name: "AddUser",
  created() {
    // this.MunicipalitieId = this.$parent.EditUsersObj?.municipalitieId;
    // this.form.Name = this.$parent.EditUsersObj?.name;
    // this.form.LoginName = this.$parent.EditUsersObj?.loginName;
    // this.form.Phone = this.$parent.EditUsersObj?.phone;
    this.form.Email = this.$parent.EditUsersObj?.Email;
    this.form.Id = this.$parent.EditUsersObj?._id;
    this.form.UserType = this.$parent.EditUsersObj?.UserType;
    this.form.name = this.$parent.EditUsersObj?.name;
    this.form.group = this.$parent.EditUsersObj?.group;
    this.form.Phone = this.$parent.EditUsersObj?.Phone;
    this.GetInfo();
    this.form.office = this.$parent.EditUsersObj?.office._id;

    // this.GetHospitalsName();
  },
  data() {
    return {
      Municipalities: [],
      MunicipalitId: "",
      pageNo: 1,
      groups: [],
      pageSize: 10,
      pages: 0,
      offices: [],
      UserType: [
        {
          id: 1,
          name: "رئيس مكتب",
        },
        {
          id: 2,
          name: "رئيس قسم",
        },
        {
          id: 3,
          name: "عضو",
        },
      ],
      Hospitals: [],
      form: {
        group: null,
        name: "",
        Password: null,
        Name: "",
        UserType: "",
        Email: "",
        office: "",
        Phone:'',

      },
      ConfimPassword: "",
    };
  },
  methods: {
   async GetInfo() {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha('login');
      this.$http.GetGroups(1, 100, reCAPTCHA).then((response) => {
          this.$blockUI.Stop();
          this.groups = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    UserTypeSearch() {},

    GetHospitalsName() {
      this.$blockUI.Start();
      this.$http
        .GetKednyCenterByCitie(this.MunicipalitId)
        .then((response) => {
          this.$blockUI.Stop();
          this.Hospitals = response.data.info;
        })
        .catch(() => {
          this.$blockUI.Stop();
        });
    },

    Back() {
      this.$parent.state = 0;
    },
    resetform() {
      this.form.Password = null;
      this.form.UserType = "";
      this.form.Email = "";
      this.ConfimPassword = "";
      this.form.office = null;
      this.name = null;
    },

    validEmail: function(email) {
      var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validLoginName: function(LoginName) {
      var login = /^[a-zA-Z]{0,40}$/;
      return login.test(LoginName);
    },
    validFullName: function(Name) {
      var loginArabic = /^[\u0621-\u064A\u0660-\u0669 ]+$/;
      return loginArabic.test(Name);
    },

    validPassword: function(Password) {
      var PasswordT = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}.*$/;

      return PasswordT.test(Password);
    },
    validPhone: function(Phone) {
      var mobileRegex = /^09[1|2|3|4|5][0-9]{7}$/i;

      return mobileRegex.test(Phone);
    },

    async Save() {
      this.$blockUI.Start();

      if (!this.form.name) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الاسم ",
        });
        return;
      }

      if (!this.form.Email) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال البريد الإلكتروني ",
        });
        return;
      } else if (!this.validEmail(this.form.Email)) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال البريد الإلكتروني بطريقه صحيحه ",
        });
        return;
      }

      // if (this.form.UserType != 1) {
      //   if (!this.form.office) {
      //     this.$blockUI.Stop();
      //     this.$message({
      //       type: "error",
      //       message: "الرجاء إختيار القسم ",
      //     });
      //     return;
      //   }
      // }

      if (!this.form.group) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال المجموعة ",
        });
        return;
      }

      if (!this.form.Phone) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء رقم الهاتف ",
        });
        return;
      } else if (!this.validPhone(this.form.Phone)) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
        });
        return;
      }

      if (!this.form.Password) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الرقم السري ",
        });
        return;
      }
      if (this.form.Password.length <= 6) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الرقم السري تحتوي علي سته ارقام ",
        });
        return;
      }

      if (!this.ConfimPassword) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال تأكيد الرقم السري ",
        });
        return;
      }

      if (this.form.Password != this.ConfimPassword) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء التأكد من تطابق الرقم السري",
        });
        return;
      }

      if (!this.form.UserType) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إختيار الصفة الوظيفية",
        });
        return;
      }

      //this.form.UserType = this.$parent.UserType;
      //this.form.OfficeId = (this.$parent.OfficeIdValue == "" ? 0 : this.$parent.OfficeIdValue);
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha('login');
      if (this.$parent.EditUsersObj) {
        return this.$http
          .updateUser(this.$parent.EditUsersObj._id, this.form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
             
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }

      this.$http
        .AddUser(this.form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;
           
          this.$blockUI.Stop();
        })
        .catch((err) => {
          //
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
  },
};
