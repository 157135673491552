export default {
  name: "Login",
  components: {},
  created() {
    this.$blockUI.$loading = this.$loading;
    this.token = this.$route.params.token;
    this.state = this.$route.params.state;
  },
  data() {
    return {
      NewPassword: "",
      ConfirmPassword: "",
      state: 0,
      token: "",
    };
  },
  methods: {
    async PasswordReset() {
      if (!this.NewPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري ",
          type: "error",
        });
      }
      if (this.NewPassword.length <= 6) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري تحتوي علي سبعة ارقام ",
          type: "error",
        });
      }
      if (!this.ConfirmPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال تأكيد الرقم السري ",
          type: "error",
        });
      }
      if (this.NewPassword != this.ConfirmPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء التأكد من تطابق الرقم السري",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .AssignNewPassword(
          {
            NewPassword: this.NewPassword,
            forgetPasswordPhoneNumber: this.forgetPasswordPhoneNumber,
          },
          reCAPTCHA,
          this.token
        )
        .then(() => {
          this.$blockUI.Stop();
          this.$notify({
            type: "success",
            title: "لقد تم تعين كلمة المرور بنجاح يمكنك يسجيل دخول...",
          });
          setTimeout(() => {
            window.location.replace(process.env.VUE_APP_SubLink);
          }, 2000);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$blockUI.Stop();
            setTimeout(() => {
              window.location.replace(process.env.VUE_APP_SubLink);
            }, 2000);
             this.$notify({
              title: "Sorry",
              message: "This page is not valid any more !!!!",
              type: "error",
            });
            
          }
        });
    },
  },
};
