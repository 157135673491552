import soundPath from "../../../assets/notifi.mp3";
import { encode, decode } from 'js-base64';
export default {
  name: "Login",
  components: {},
  data() {
    return {
      disable: false,
      NewPassword: "",
      ConfirmPassword: "",
      otpState: false,
      state: 0,
      blokState: 0,
      isAuthenticated: false,
      isActive: false,
      ConfimPassword: "",
      forgetPasswordPhoneNumber: "",
      otp: "",
      form: {
        Name: "حملة إفطار صائم غزاوي",
        phoneNumber: "0928769540",
      },
      signUp: {
        Name: "",
        PhoneNumber: "",
        voucher: "",
        Email: "",
        Description: "",
      },
    };
  },
  methods: {
    async login() {
      if (!this.form.voucher) {
        this.$notify({
          title: "خطأ",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء ادخال رمز الكرت" + "</strong>",
          type: "error",
        });
        return;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .redeemAPIGAZA(this.form.voucher, reCAPTCHA)
        .then(() => {
          this.$blockUI.Stop();
          new Audio(soundPath).play();
          this.$blockUI.Stop();
          this.$notify({
            type: "success",
            title: "صدقتك قد وصلت بنجاح",
            dangerouslyUseHTMLString: true,
            message:
              "<strong>" +
              "جزاك الله خيراً عن مساهمتكم في اغاثة أهلنا في غزة" +
              "</strong>",
          });
          
        })
        .catch((error) => {
          this.$blockUI.Stop();
          this.$notify({
            title: "خطأ",
            dangerouslyUseHTMLString: true,
            message: "<strong>  الرجاء التأكد من صحة الكرت  </strong>",
            type: "error",
          });
        });
    },
  },
};
