import soundPath from "../../../assets/notifi.mp3";
import { encode, decode } from 'js-base64';
export default {
  name: "Login",
  components: {},
  beforeMount() {
    if (
      !this.$route.params.UrlKey ||
      !this.$route.params.UrlId ||
      !this.$route.params.id ||
      !this.$route.params.Name
    ) {
      return this.$router.replace("/PageNotFound");
    }
    if (
      this.$route.params.UrlKey.length != 100 ||
      this.$route.params.UrlId.length != 100 ||
      this.$route.params.id.length != 24
    ) {
      return this.$router.replace("/PageNotFound");
    }
    this.form.Name = atob(this.$route.params.Name);
    this.form.UrlKey = this.$route.params.UrlKey;
    this.form.UrlId = this.$route.params.UrlId;
    this.form.id = this.$route.params.id;
  },
  created() {},
  data() {
    return {
      disable: false,

      NewPassword: "",
      ConfirmPassword: "",
      otpState: false,
      state: 0,
      blokState: 0,
      isAuthenticated: false,
      isActive: false,
      ConfimPassword: "",
      forgetPasswordPhoneNumber: "",
      otp: "",
      form: {
        Name: "",
        UrlKey: null,
        UrlId: null,
        id: null,
        phoneNumber: null,
      },
      signUp: {
        Name: "",
        PhoneNumber: "",
        voucher: "",
        Email: "",
        Description: "",
      },
    };
  },
  methods: {
    async logout() {
      localStorage.clear();
      sessionStorage.clear();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .logout(reCAPTCHA)
        .then((e) => {})
        .catch((e) => {});
    },
    async PasswordReset() {
      if (!this.NewPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري ",
          type: "error",
        });
      }
      if (this.NewPassword.length <= 6) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري تحتوي علي سبعة ارقام ",
          type: "error",
        });
      }
      if (!this.ConfirmPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال تأكيد الرقم السري ",
          type: "error",
        });
      }
      if (this.NewPassword != this.ConfirmPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء التأكد من تطابق الرقم السري",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .AssignNewPassword(
          {
            NewPassword: this.NewPassword,
            forgetPasswordPhoneNumber: this.forgetPasswordPhoneNumber,
          },
          reCAPTCHA
        )
        .then(() => {
          this.$blockUI.Stop();
          this.state = 0;
          return this.$notify({
            type: "success",
            title: "لقد تم تعين كلمة المرور بنجاح يمكنك يسجيل دخول...",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },
    async ForgetPassowrd() {
      if (!this.forgetPasswordPhoneNumber) {
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء إدخال  رقم الهاتف" + "</strong>",
          type: "error",
        });
      } else if (!this.validPhone(this.forgetPasswordPhoneNumber)) {
        // this.increment();
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" + "الرجاء إدخال  رقم الهاتف بطريقة صحيحة" + "</strong>",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .ForgetPassword(
          { PhoneNumber: this.forgetPasswordPhoneNumber },
          reCAPTCHA
        )
        .then(() => {
          this.$blockUI.Stop();
          this.state = 4;
          return this.$notify({
            type: "success",
            title: "لقد تم إرسال رمز التحقق الي رقم الهاتف",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },
    async otpresend() {
      this.otpState = false;
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .otpresend({ PhoneNumber: this.signUp.PhoneNumber }, reCAPTCHA)
        .then(() => {
          setTimeout(() => {
            this.otpState = true;
          }, 20000);
          this.$blockUI.Stop();
          return this.$notify({
            type: "success",
            title: "لقد تم إرسال رمز التحقق من جديد",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },
    async checkOtpPassswordForget() {
      if (!this.otp) {
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message: "الرجاء إدخال  رمز التحقق",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .checkOtpForgetPassword(
          { PhoneNumber: this.forgetPasswordPhoneNumber, otp: this.otp },
          reCAPTCHA
        )
        .then(() => {
          this.state = 5;
          this.$blockUI.Stop();
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },

    async checkOtp() {
      if (!this.otp) {
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message: "الرجاء إدخال  رمز التحقق",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .checkOtp(
          { PhoneNumber: this.signUp.PhoneNumber, otp: this.otp },
          reCAPTCHA
        )
        .then(() => {
          this.state = 0;
          new Audio(soundPath).play();
          this.$blockUI.Stop();
          return this.$notify({
            type: "success",
            title: "Great",
            dangerouslyUseHTMLString: true,
            message:
              "<strong>" +
              "لقد تم تسجيل حساب سيتم اعلامك من قبل المنصة بتفعيل حسابك" +
              "</strong>",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$notify({
            title: "Sorry",
            message: error.response.data.Message,
            type: "error",
          });
        });
    },
    increment() {
      this.blokState += 1;
      if (this.blokState > 3) {
        localStorage.setItem("BSDB", true);
      }
    },
    SwitchMode(item) {
      this.state = item;
    },
    async Checks() {
      if (localStorage.getItem("BSDB") == "true") {
        setTimeout(() => {
          window.location.href = "https://youtu.be/uRu26Zr7Jko?t=33";
        }, 7000);
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" +
            "Your device has been blocked contact the Admin.  We are Legion. We do not forgive. We do not forget." +
            "</strong>",
          type: "error",
        });
      }

      if (!this.signUp.PhoneNumber) {
        // this.increment();
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء إدخال  رقم الهاتف" + "</strong>",
          type: "error",
        });
      } else if (!this.validPhone(this.signUp.PhoneNumber)) {
        // this.increment();
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" + "الرجاء إدخال  رقم الهاتف بطريقة صحيحة" + "</strong>",
          type: "error",
        });
      }

      if (!this.signUp.Password) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري ",
          type: "error",
        });
      }
      if (this.signUp.Password.length <= 6) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال الرقم السري تحتوي علي سبعة ارقام ",
          type: "error",
        });
      }

      if (!this.ConfimPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء إدخال تأكيد الرقم السري ",
          type: "error",
        });
      }

      if (this.signUp.Password != this.ConfimPassword) {
        return this.$notify({
          title: "Sorry",
          message: "الرجاء التأكد من تطابق الرقم السري",
          type: "error",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .AddTeacher(this.signUp, reCAPTCHA)
        .then(() => {
          this.$blockUI.Stop();
          this.signUp.Name = "";
          // this.signUpPhoneNumber = "";
          this.signUpPassword = "";
          this.signUpEmail = "";
          this.signUpDescription = "";
          this.state = 2;
          setTimeout(() => {
            this.otpState = true;
          }, 20000);
          return this.$notify({
            type: "success",
            title: "Great",
            dangerouslyUseHTMLString: true,
            message:
              "<strong>" +
              "لقد تم تسجيل حساب وإرسال رمز التحقق على رقم الهاتف" +
              "</strong>",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          this.$notify({
            title: "خطأ",
            dangerouslyUseHTMLString: true,
            message: "<strong>" + error.response.data.Message + "</strong>",
            type: "error",
          });
        });
    },
    signUp2() {
      this.increment();
      if (localStorage.getItem("BSDB") == "true") {
        setTimeout(() => {
          window.location.href = "https://youtu.be/uRu26Zr7Jko?t=33";
        }, 7000);

        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" +
            "Your device has been blocked contact the Admin  We are Legion. We do not forgive. We do not forget." +
            "</strong>",
          type: "error",
        });
      }
      this.$notify({
        title: "Sorry",
        dangerouslyUseHTMLString: true,
        message: "<strong>" + "Do you think it's that easy?" + "</strong>",
        type: "error",
      });
    },
    validPhone: function (Phone) {
      var mobileRegex = /^09[1|2|3|4|5][0-9]{7}$/i;
      return mobileRegex.test(Phone);
    },
    async login() {
      if (localStorage.getItem("BSDB") == "true") {
        this.disable = true;
        return this.$notify({
          title: "Sorry",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" +
            "Your device has been blocked contact the Admin." +
            "</strong>",
          type: "error",
        });
      }

      if (!this.form.phoneNumber) {
        this.increment();
        return this.$notify({
          title: "خطأ",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء إدخال  رقم الهاتف" + "</strong>",
          type: "error",
        });
      } else if (!this.validPhone(this.form.phoneNumber)) {
        this.increment();
        return this.$notify({
          title: "خطأ",
          dangerouslyUseHTMLString: true,
          message:
            "<strong>" + "الرجاء إدخال  رقم الهاتف بطريقة صحيحة" + "</strong>",
          type: "error",
        });
      }

      if (!this.form.voucher) {
        this.$notify({
          title: "خطأ",
          dangerouslyUseHTMLString: true,
          message: "<strong>" + "الرجاء إدخال الرقم السري" + "</strong>",
          type: "error",
        });
        this.increment();
        return;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .redeemOutSide(this.form, reCAPTCHA)
        .then(() => {
          this.$blockUI.Stop();
          new Audio(soundPath).play();
          this.$blockUI.Stop();
          this.$notify({
            type: "success",
            title: "لقد تم تعبئة الكرت",
            dangerouslyUseHTMLString: true,
            message:
              "<strong>" +
              "لقد تم تعبئة الكرت بنجاح يمكنك التواصل مع المسؤول" +
              "</strong>",
          });
          // setTimeout(() => {
          //   window.location.href = "https://www.facebook.com";
          // }, 7000);
        })
        .catch((error) => {
          this.$blockUI.Stop();
          this.increment();
          this.$notify({
            title: "خطأ",
            dangerouslyUseHTMLString: true,
            message: "<strong>" + error.response.data.Message + "</strong>",
            type: "error",
          });
        });
    },
  },
};
