import Swal from 'sweetalert2'
import moment from 'moment';
import Actions from './Actions/Actions.vue';
export default {
    name: 'index',
    created(){
        this.GetInfo();
    },
    components: {
        'Actions':Actions
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            name:null,
            code:null,
            Info: [],
            UserType: '',
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            EditObj: [],
        };
    },
    methods: {
        refresh() {
            this.UserType = '';
            this.GetInfo();
        },
        
        UserTypeSearch() {
            this.GetInfo();
        },

        async GetInfo(pageNo = 1) {
            this.Info = [];
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            await this.$recaptchaLoaded();
            let reCAPTCHA = await this.$recaptcha('login');
            this.$http.GetGroups(this.pageNo, this.pageSize, reCAPTCHA)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch((error) => {
                    if (error.response.status==401) {
                        return  window.location = "/"
                 }
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        Delete(Id) {
            Swal.fire({
                title: 'هـل انت متأكد من  حذف  ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    await this.$recaptchaLoaded();
                    let reCAPTCHA = await this.$recaptcha('login');
                    this.$http.DeleteGroup(Id,reCAPTCHA)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data.Message,
                                    showCancelButton: false,
                                    denyButtonText: `خروج`,
                            }).then(() => {});
                            this.$blockUI.Stop();
                            this.GetInfo(1);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية التفعيل',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data.Message
                            });
                        });
                    return;
                }
            })
        },

        Edit(item) {
            this.state = 2;
            this.EditObj = item;
        },

        Addpage() {
            this.state = 2;
            this.EditObj = null;
        },  
    }
}
