/////////////////////////////////   Sales   /////////////////////////////////////////////
import Vue from "vue";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import Vuetify from "vuetify";
import locale from "element-ui/lib/locale/lang/en";
import BlockUIService from "./Shared/BlockUIService.js";
import router from "./router.js";
import VueRouter from "vue-router";
import App from "./App.vue";
import DataService from "./Shared/DataService";
import Helper from "./Shared/Helper";
import "../node_modules/nprogress/nprogress.css";

import { VueReCaptcha } from "vue-recaptcha-v3";
// import socket from "./Shared/socketio.service.js"
import axios from "axios";
// import VueEllipseProgress from "vue-ellipse-progress";
axios.defaults.withCredentials = true;
// import VueSocketIO from 'vue-socket.io';
// import io from 'socket.io-client';
// const SocketInstance = io.connect('http://localhost:4000/', {
// });
// wsInit();
// ws().onopen = () => {
// console.log('ws connected')
// }
// ws().onclose = () => {
//   console.log('Connection closed');
// };
// Vue.use(VueSocketio, '');
// Vue.use(new VueSocketIO({
//   debug: false,
//   connection: SocketInstance
// }));
// socket.setupSocketConnection();
// Vue.use(VueEllipseProgress);

import excel from "vue-excel-export";
import PrimeVue from "primevue/config";

Vue.use(excel);
Vue.use(VueReCaptcha, { siteKey: "6Lda9xElAAAAALtIZWprSOdQ5doqXVO7afJi-qgy" });
Vue.use(Vuetify);
Vue.use(PrimeVue);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(ElementUI, { locale });
Vue.config.productionTip = false;
Vue.prototype.$http = DataService;
Vue.prototype.$blockUI = BlockUIService;
Vue.prototype.$helper = Helper;
export const eventBus = new Vue();
Vue.filter("toUpperCase", function (value) {
  if (!value) return "";
  return value.toUpperCase();
});

router.beforeEach((to, from, next) => {
  if (
    to.name == "PageNotFound" ||
    to.name == "login" ||
    to.name == "redeem" ||
    to.name == "gaza" ||
    to.name == "ResetPassword"
  ) {
    return next();
  }
  if (to.fullPath !== "/login") {
    if (!localStorage.getItem("Token")) {
      return next({ path: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  render: (h) => {
    return h(App);
  },
}).$mount("#cpanel-management");
