import Chart from "primevue/chart";
import { FormatsLYD } from "../helper/Formats.js";

export default {
  name: "home",
  components: {
    Chart,
  },
  created() {
    window.scrollTo(0, 0);
    this.transmission = localStorage.getItem("transmission");
    this.Name = localStorage.getItem("name");
    if (this.transmission == 12) {
      this.GetClients();
    }
    this.GetVouchersHomeStatics();
  },
  data() {
    return {
      clients: [],
      clientId:'',
      transmission: null,
      Statics: {},
      Distributors: [],
      pageNo: 1,
      pageSize: 25,
      Info: [],
      pages: null,
      chartData: {
        labels: ["اجمالي الكروت", "تم بيعه", "لم يتم بيعه"],
        datasets: [
          {
            data: [0, 0, 0],
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
          },
        ],
      },
      chartOptions: {
        legend: {
          labels: {
            fontColor: "#495057",
          },
        },
      },
    };
  },
  methods: {
    async GetClients() {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetAllForSearch(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.clients = response.data.info;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
        });
    },
    ValueInLyd(value) {
      return FormatsLYD(value, 3);
    },
    Clear(){
        this.clientId = null;
    },
    async GetVouchersHomeStatics() {
      this.GetInfo(1);
      if (this.transmission == 12) {
        this.$blockUI.Start();
        await this.$recaptchaLoaded();
        let reCAPTCHA = await this.$recaptcha("login");
        this.$http
          .GetVouchersHomeStaticsSearch(this.clientId,reCAPTCHA)
          .then((response) => {
            this.$blockUI.Stop();
            this.Statics = response.data;
            this.chartData.datasets[0].data = [
              this.Statics.totalCards,
              this.Statics.totalCardsPurchased,
              this.Statics.totalCardsNotPurchased,
            ];
          })
          .catch((error) => {
            if (error.response.status == 401) {
              return (window.location = "/");
            }
            this.$blockUI.Stop();
          });
      } else {
        this.$blockUI.Start();
        await this.$recaptchaLoaded();
        let reCAPTCHA = await this.$recaptcha("login");
        this.$http
          .GetVouchersHomeStatics(reCAPTCHA)
          .then((response) => {
            this.$blockUI.Stop();
            this.Statics = response.data;
            this.chartData.datasets[0].data = [
              this.Statics.totalCards,
              this.Statics.totalCardsPurchased,
              this.Statics.totalCardsNotPurchased,
            ];
          })
          .catch((error) => {
            if (error.response.status == 401) {
              return (window.location = "/");
            }
            this.$blockUI.Stop();
          });
      }
    },
    async GetInfo(pageNo = 1) {
      this.openDialog = false;
      this.Info = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetVR(this.pageNo, this.pageSize, "", "", reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.result;
          this.pages = response.data.count;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },
  },
};
