export default {
  name: "Index",
  created() {},
  data() {
    return {
      FQA: [
        {
          name: "what is your birth date",
          des: " asn asd askdjb kjasbkj baskjd bkajsb kjasb kjasbk djbaskdjbad",
        },
        {
          name: "what is your birth date",
          des: " asn asd askdjb kjasbkj baskjd bkajsb kjasb kjasbk djbaskdjbad",
        },
        {
          name: "what is your birth date",
          des: " asn asd askdjb kjasbkj baskjd bkajsb kjasb kjasbk djbaskdjbad",
        },
        {
          name: "what is your birth date",
          des: " asn asd askdjb kjasbkj baskjd bkajsb kjasb kjasbk djbaskdjbad",
        },
        {
          name: "what is your birth date",
          des: " asn asd askdjb kjasbkj baskjd bkajsb kjasb kjasbk djbaskdjbad",
        }, {
          name: "what is your birth date",
          des: " asn asd askdjb kjasbkj baskjd bkajsb kjasb kjasbk djbaskdjbad",
        }, {
          name: "what is your birth date",
          des: " asn asd askdjb kjasbkj baskjd bkajsb kjasb kjasbk djbaskdjbad",
        }, {
          name: "what is your birth date",
          des: " asn asd askdjb kjasbkj baskjd bkajsb kjasb kjasbk djbaskdjbad",
        },
      ],
    };
  },
};
