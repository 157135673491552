import VueRouter from "vue-router";

/////////////////////////////////   Sales   /////////////////////////////////////////////
import Layout from "./components/Layout/Layout.vue";
import profile from "./components/Sales/Profile/Index.vue";
import Login from "./components/Sales/Login/Login.vue";
import ResetPassword from "./components/Sales/ResetPassword/Login.vue";
import Home from "./components/Home/Home.vue";
import Teachers from "./components/Sales/Teachers/Index.vue";
import redeem from "./components/Sales/redeem/index.vue";
import API from "./components/Sales/Api/Index.vue";
import Invoices from "./components/Sales/invoices/Index.vue";
import Category from "./components/Sales/Category/Index.vue";
import CreateURl from "./components/Sales/CreateURl/Index.vue";
import EndSubscription from "./components/Sales/EndSubscription/Index.vue";
import customer from "./components/Sales/Customer/Index.vue";
import CardsCheck from "./components/Sales/CardsCheck/Add_Edit.vue";
import Users from "./components/Sales/Users/Users.vue";
import reports from "./components/Reports/Reports.vue";
import Groups from "./components/Sales/Groups/index.vue";
import FrequentlyAskedQuestions from "./components/Sales/FrequentlyAskedQuestions/Index.vue";
import PageNotFound from "./components/PageNotFound/Index.vue";
import Distributor from "./components/Sales/Distributor/Index.vue";
import Orders from "./components/Sales/Orders/Index.vue";
import gaza from "./components/Sales/gaza/index.vue"

const routes = [
  {
    path: "/",
    redirect: "/login",

  },
  {
    path: "/PageNotFound",
    component: PageNotFound,
    name:'PageNotFound',
  },
  {
    path: "/Login",
    component: Login,
    name:'login'
  },
  {
    path:"/gaza",
    component:gaza,
    name:"gaza"
  },
  {
    path: "/redeem/:UrlKey/:UrlId/:id/:Name",
    component: redeem,
    name:'redeem'

  },
  {
    path: "/ResetPassword/:token/:state",
    component: ResetPassword,
    name:'ResetPassword'
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/customer",
        name: "الزبائن",
        component: customer,
        meta: { isAllowd: true },
      },
      
      {
        path: "/CardsCheck",
        name: "تأكد من كرت",
        component: CardsCheck,
        meta: { isAllowd: true },
      }, 
      {
        path: "/CreateURl",
        name: "تكوين رابط",
        component: CreateURl,
        meta: { isAllowd: true },
      },
      
      {
        path: "/Category",
        name: "فئات الكروت",
        component: Category,
        meta: { isAllowd: true },
      },
      {
        path: "/FrequentlyAskedQuestions",
        name: "الاسئــلة الشائعة",
        component: FrequentlyAskedQuestions,
        meta: { isAllowd: true },
      },
      {
        path: "/Users",
        name: "المستخدمين",
        component: Users,
        meta: { isAllowd: true },
      },
      {
        path: "/Api",
        name: "API",
        component: API,
        meta: { isAllowd: true },
      },
      {
        path: "/Accounts",
        name: "الحسابات",
        component: Teachers,
        meta: { isAllowd: true },
      },
      {
        path: "/Groups",
        component: Groups,
        name: "الصلاحيـــات",
        meta: { isAllowd: true },
      },
      {
        path: "/Home",
        component: Home,
        name: "الرئيسيــة",
        meta: { isAllowd: true },
      },
      {
        path: "reports",
        component: reports,
        name: "التقاريــر",
        meta: { isAllowd: true },
      },
      {
        path: "Orders",
        name: "طلبيات",
        component: Orders,
        meta: { isAllowd: true },
      },
      {
        path: "Distributor",
        name: "الموزعين",
        component: Distributor,
        meta: { isAllowd: true },
      },
    ],
  },
  {
    path: "*",
    redirect: "PageNotFound",
  },
];
const router = new VueRouter({
  routes,
});

export default router;
