import { FormatsLYD } from "../../helper/Formats.js";
import Swal from "sweetalert2";

export default {
  name: "EditAdd",
  created() {
    this.Name = localStorage.getItem("name");
    // this.GetInfo(1);
  },
  data() {
    return {
      card:"",
      CardShow:false,
      isTest: false,
      openDialogForExcl: false,
      Cards: [
        { Name: "كروت عشرة دينار (10د)", id: 10 },
        { Name: "كروت خمسين دينار (50د) ", id: 50 },
        { Name: "كروت مئة دينار (100د)", id: 100 },
        { Name: "كروت مئتان دينار (200د)", id: 200 },
        { Name: "كروت خمسمئة دينار (500د)", id: 500 },
      ],
      openDialog: false,
      number: "",
      Info: [],
      Statement: "",
      isPurchasedStates: [
        { Name: "تم البيع", id: true },
        { Name: "لم يتم البيع", id: false },
      ],
      Name: "",
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      value: "",
      SearchSn: "",
      count: "",
      actualVouchersValue: "",
      faceVouchersValue: "",
      SearchIsPurchased: "",
      isloding: false,
      SearchValue: "",
    };
  },

  methods: {
    async ShowCard(id){
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .ShowCard(
          id,
          reCAPTCHA
        )
        .then((response) => {
          this.CardShow= true;
          this.$blockUI.Stop();
          this.card = response.data.info;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
        })
    },
    Redeem(id) {
      Swal.fire({
        title: `هل انت متأكد من شراء الكرت`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .RedeemCardsVouchersDashboard(
              id,
              reCAPTCHA
            )
            .then((response) => {
              this.$blockUI.Stop();
              this.$message({
                type: "success",
                message: "لقد تم شراء الكرت بنجاح يمكنك عرض الكرت",
              });
              return;
            })
            .catch((error) => {
              this.$blockUI.Stop();
              if (error.response.status == 401) {
                return (window.location = "/");
              }
              this.$message({
                type: "error",
                message: error.response.data.Message,
              });
              return;
            });
        }
      });
    },
    toolbarClick: function (args) {
      if (args.item.id === "Grid_excelexport") {
        this.$refs.grid.excelExport();
      }
    },
    ValueInLyd(value) {
      return FormatsLYD(value, 3);
    },
    // async GetCards() {
    //   this.$blockUI.Start();
    //   await this.$recaptchaLoaded();
    //   let reCAPTCHA = await this.$recaptcha("login");
    //   this.$http
    //     .GetCardsForExl(
    //       this.$parent.EditAddItem?._id,
    //       this.SearchValue,
    //       this.isTest,
    //       reCAPTCHA
    //     )
    //     .then((response) => {
    //       debugger;
    //       this.$blockUI.Stop();
    //       let result = [];
    //       response.data.info.forEach((element) => {
    //         result.push({
    //           sn: element.sn,
    //           faceValue: element.faceValue,
    //           actualValue: element.actualValue,
    //           code: element.code,
    //         });
    //       });
    //       if (result.length != 0) {
    //         let data = this.formatJson(
    //           ["sn", "faceValue", "actualValue", "code"],
    //           result
    //         );
    //         import("../../../assets/Ex.js").then((excel) => {
    //           excel.export_json_to_excel({
    //             header: ["SN", "Face Value", "Actual Value", "Code"], //Header Required
    //             data, //Specific data Required
    //             filename: this.Name, //Optional
    //             autoWidth: false, //Optional
    //             bookType: "xlsx", //Optional
    //           });
    //         });
    //       }

    //       // this.openDialogForExcl = true;
    //     })
    //     .catch((error) => {
    //       if (error.response.status == 401) {
    //         return (window.location = "/");
    //       }
    //       this.$blockUI.Stop();
    //     });
    // },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    Clear() {
      this.SearchValue = "";
      this.SearchIsPurchased = "";
      this.SearchSn = "";
      // this.GetInfo(1);
    },
    async GetInfo(pageNo = 1) {
      this.openDialog = false;
      this.Info = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .CheckCard(
          this.pageNo,
          this.pageSize,
          this.SearchValue,
          this.SearchSn,
          this.SearchIsPurchased,
          this.isTest,
          reCAPTCHA
        )
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.pages = response.data.count;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },
    ShowDialog() {
      this.number = "";
      this.value = "";
      this.openDialog = true;
    },
    Back() {
      this.$parent.state = 0;
    },
    async Save() {
      Swal.fire({
        title: `هـل انت متأكد من إضافة ${this.number} كرت ؟`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isloding = true;
          if (!this.value || !this.number) {
            this.isloding = false;
            this.$message({
              type: "error",
              message: "الرجاء ادخال كافة الحقول ",
            });
            return;
          }
          if (!this.$parent.EditAddItem._id) {
            this.isloding = false;
            this.$message({
              type: "error",
              message: "الرجاء العودة لصفحة السابقة وإعادة المحاولة ",
            });
            return;
          }
          if (this.number < 1) {
            this.isloding = false;
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: "الرجاء ادخال قيمة صحيحة للتوليد ",
            });
            return;
          }
          if (this.number > 200) {
            this.isloding = false;
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: "الرجاء ادخال قيمة اقل من 200 كرت في العملية الواحدة ",
            });
            return;
          }
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$blockUI.Start();
          let schema = {
            isTest: this.isTest,
            value: this.value,
            number: this.number,
            RVId: this.$parent.EditAddItem._id,
          };
          this.$http
            .CreateBulkVouchers(schema, reCAPTCHA)
            .then((response) => {
              this.$message({
                type: "info",
                message: response.data.Message,
              });
              this.isloding = false;
              this.openDialog = false;
              this.GetInfo(1);
              // this.$parent.GetInfo(this.$parent.pageNo);
              this.$blockUI.Stop();
            })
            .catch((err) => {
              this.isloding = false;
              this.$blockUI.Stop();
              console.log(err);
              this.$message({
                type: "error",
                message: err.response.data.Message,
              });
            });
        }
      });
    },
  },
};
